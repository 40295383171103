@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5-dark.css';
@import '../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css';

body {
    touch-action: none !important;
}
/* .control-section {
  padding: 20px;
} */
.e-grid {
    border-radius: 5px !important;
}

.e-groupdroparea {
    border-radius: 5px 5px 0 0 !important;
}

.e-gridheader {
    border-radius: 5px 5px 0 0 !important;
}

.e-control {
    border-radius: 5px !important;
}

.e-grid .e-headercell.e-removewrap .e-headercelldiv,
.e-grid .e-rowcell.e-removewrap {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow-wrap: nowrap !important;
}

.e-grid .e-toolbar .e-tbar-btn-text,
.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    display: none;
}

.e-grid .e-gridheader {
    margin-top: 1px !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
    margin-left: 4px !important;
}

/* .e-toolbar .e-toolbar-items{
  background: rgba(40, 45, 49, 1);
} */

.paging-api .e-pager .e-pagecountmsg {
    display: inline-block;
}
.paging-api .e-pager .e-pagenomsg {
    display: none;
}
@media screen and (max-width: 451px) {
    .paging-api .e-grid .e-pager .e-pagecountmsg {
        display: none;
    }
    .paging-api .e-grid .e-pager .e-pagenomsg {
        display: inline-block;
    }
}

.e-grid .e-fleet {
    width: 2rem !important;
    height: 2rem !important;
    display: inline-flex;
    margin: 0px !important;
    align-items: center;
    justify-content: center;
}

/* This is not working primarily used for syncfusion grid command icon */
.e-statutory:before {
    font-family: 'Font Awesome 5 Free'; /* Ensure this matches the FontAwesome version you're using */
    content: '\e564'; /* Unicode for the truck icon */
    font-weight: 900; /* For solid icons */
    /* margin-right: 8px; */
}

.e-grid .e-rowcell {
    /* line-height: 1rem !important; */
    /* margin: 0px !important; */
    padding: 4px 6px !important;
}

/* .e-grid * {
  font-size: 14px !important;
} */

/* .e-grid .e-altrow {
  background-color: #fafafa;
} */

/* .e-grid .e-selectionbackground {
  background-color: #f9920b;
} */

/* .e-grid .e-rowcell {
  line-height: 0.5rem !important;
} */

.e-btn-icon {
    transition: color 0.3s ease; /* Add smooth transition for color change */
}

.e-btn-icon.e-delete {
    color: red !important;
}

.e-btn-icon.e-circle-check {
    color: green !important;
}

.e-btn-icon.e-circle-close {
    color: red !important;
    /* content: "\e796"; */
}

.e-btn-icon.e-export {
    color: red !important;
}

.e-btn-icon:hover {
    color: white !important;
}

.e-pagerconstant .e-constant {
    display: none;
}

.e-grid .e-rowcell.customcss {
    background-color: #ecedee;
}

.e-grid .e-headercell.customcss {
    background-color: #ecedee;
}

.spinnerlessgrid.e-grid .e-spinner-pane {
    display: none;
}

.customcss {
    background-color: yellow;
    color: black !important;
}

fieldset.darktheme:disabled input:not([type='checkbox']),
fieldset.darktheme:disabled textarea {
    background: rgb(173, 181, 189, 0.32) !important;
    color: rgb(188, 188, 188, 1) !important;
    cursor: not-allowed;
}

fieldset.lighttheme:disabled input:not([type='checkbox']),
fieldset.lighttheme:disabled textarea {
    background: rgb(238, 238, 238, 1) !important;
    cursor: not-allowed;
}

.dgreyout {
    background: rgba(173, 181, 189, 0.5) !important;
    color: rgb(255, 255, 255, 1) !important;
    cursor: not-allowed;
}

.lgreyout {
    background: rgb(238, 238, 238, 1) !important;
    /* color: rgb(0, 0, 0) !important; */
    cursor: not-allowed;
}

#comboelement_popup {
    margin-top: 5px !important;
    /* offset: 10px !important; */
}

/* Hide column on small screens */
@media (max-width: 768px) {
    .hide-on-small-screen {
        display: none !important;
    }
}

/* Show column on small screens */
@media (max-width: 768px) {
    .show-on-small-screen {
        display: table-cell !important;
    }
}

/* Show TaskMenu on phones/tablets screens */
@media only screen and (min-width: 1280px) {
    .show-taskmenu-on-small-screen {
        display: none !important;
    }
}

/* Small screens (phones) */
@media only screen and (max-width: 600px) {
    .e-dropdown-popup.e-control {
        width: 90%; /* Adjust the width as needed */
    }
}

/* Medium screens (tablets) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .e-dropdown-popup.e-control {
        width: 80%; /* Adjust the width as needed */
    }
}

/* Large screens (desktops) */
@media only screen and (min-width: 1025px) {
    .e-dropdown-popup.e-control {
        width: 60%; /* Adjust the width as needed */
    }
}

.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
    font-size: 16px;
    color: darkgray;
}

.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close:hover {
    font-size: 16px;
    color: rgb(255, 0, 0) !important;
}

.e-btn-icon.e-icon-dlg-close {
    transition: color 0.5s ease; /* Add smooth transition for color change */
}

.e-dialog .e-dlg-header {
    /* color: green; */
    font-size: 20px !important;
    font-weight: bold;
}

/* z-index NOT  WORKING */
/* .tippy-box {
    z-index: 10002 !important;
} */

/* .custom-dialog1 .e-dialog {
    z-index: 9998 !important; 
}
    
.custom-dialog2 .e-dialog {
    z-index: 9999 !important; 
} */

.separator-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
}

.separator-line {
    flex: 1;
    height: 1px;
    background-color: #ccc;
}

.separator-text {
    padding: 0 10px;
    color: #555;
    font-weight: bold;
    white-space: nowrap; /* Prevent text from wrapping */
}

/* This is used as we could not change the icon color in dialog header */
.iconcustomwhite path {
    fill: white;
}

.notrequired .stat {
    /* border: 1px solid gray; */
    @apply bg-gray-400;
    /* background-color: gray; */
}

.expired .stat {
    /* border: 1px solid red; */
    @apply bg-red-500;
    /* background-color: red; */
}

.warning .stat {
    /* border: 1px solid darkgoldenrod; */
    @apply bg-warning;
    /* background-color: darkgoldenrod; */
}

.working .stat {
    /* border: 1px solid green; */
    @apply bg-success;
    /* background-color: green; */
}

.scrollable-chip-container {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of child elements */
    padding: 1px; /* Optional: add some padding */
    width: 100%; /* Ensure the container takes the full width */
}

/* Define custom styles */
.custom-syncfusion-control-outline {
    border: 1px solid #4a90e2 !important; /* Customize the border color and size */
}

.synccomboboxerror.e-ddl.e-input-group.e-control-wrapper {
    border: 1px solid #e7515a !important;
    background-color: #e7515a14 !important;
    box-shadow: none !important;
}

/* .synccomboboxerror .e-dropdownbase .e-list-item,
.e-dropdownbase .e-list-item.e-item-focus {
    background-color: #29c2b8;
    color: #207cd9;
} */

.synccomboboxsuccess.e-ddl.e-input-group.e-control-wrapper {
    border: 1px solid #00ab55 !important;
    background-color: #00ab5514 !important;
    box-shadow: none !important;
    font-weight: bold !important;
    color: #00ab55 !important;
}

.synccomboboxerror.e-ddl.e-input-group input.e-input::placeholder {
    color: #e7515ac4 !important;
    opacity: 0.9 !important;
    font-weight: bold !important;
}

.synccomboboxsuccess.e-ddl.e-input-group input.e-input::placeholder {
    color: #00ab55 !important;
    opacity: 0.7 !important;
    font-weight: bold !important;
}

.synccombobox.e-input-group.e-control-wrapper.e-disabled {
    border: none !important;
    font-weight: bold !important;
}

fieldset.darktheme .synccombobox.e-input-group.e-control-wrapper .e-input[disabled] {
    -webkit-text-fill-color: #e9e9e9 !important;
    background-color: transparent !important;
}

fieldset.lighttheme .synccombobox.e-input-group.e-control-wrapper .e-input[disabled] {
    -webkit-text-fill-color: #4b515a !important;
    background-color: #eeeeee !important;
}

.emptyRecordTemplate {
    text-align: center;
}

.e-emptyRecord {
    display: block;
    margin: 1px auto;
}

.e-icons.e-red {
    color: red !important;
}

.serial-number-column {
    font-weight: regular;
    font-size: 10px;
    text-align: center;
}

:root {
    --header-bg-color: #212529;
}

.lighttheme {
    --header-bg-color: white;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
    background-color: var(--header-bg-color);
}
